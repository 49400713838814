<template>
 
    <div class="modal fade" id="ViewBookASeatsDialog" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-dialog-centered modal-xl">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body">
         
            <table id="data-table-combine-viewbookings" class="table table-striped table-bordered data-table-combine data-table-combine-viewbookings">
              <thead>
                <tr>
                  <th width="1%" data-orderable="false"></th>
                  <th class="text-nowrap">Date</th>
                  <th class="text-nowrap">Booking No</th>
                  <th class="text-nowrap">Origin</th>
                  <th class="text-nowrap">Destination</th>
                  <th class="text-nowrap">Travel Time</th>
                  <!-- <th class="text-nowrap">Duration</th> -->
                  <th class="text-nowrap">Price</th>
                  <th class="text-nowrap">Status</th>
                  <th class="text-nowrap"></th>
                  <!-- <th class="text-nowrap">Num Of Passangers</th>
                  <th class="text-nowrap">Total Fare Generated</th> -->
                </tr>
              </thead>

               <tbody>
                <tr v-for="(item,index) in GETALLTRANS" :key="item.bookaseatid">
                      <td width="1%" class="">{{++index}}</td>
                      <td>{{item.booking_date}} </td>
                      <td>{{item.bookingnumber}} </td>
                      <td>{{item.from_rank_name}}</td>
                      <td>{{item.from_route_name}}</td>
                      <td>{{item.description}}</td>
                      <!-- <td>{{item.travel_time_in_minutes}}</td> -->
                      <td>{{item.booking_amount | newcurrencywithSymbol}}</td>
                      <td>
                        <span v-if="item.bookingstatus_name == 'Accepted'" class="btn-success badge">{{item.bookingstatus_name}}</span>
                        <span v-if="item.bookingstatus_name == 'CheckIn'" class="badge btn-success">{{item.bookingstatus_name}}</span>
                        <span v-if="item.bookingstatus_name == 'Cancelled'" class="badge btn-danger">{{item.bookingstatus_name}}</span>
                        <span v-if="item.bookingstatus_name == 'Waiting List'" class="badge btn-warning">{{item.bookingstatus_name}}</span>
                        <span v-if="item.bookingstatus_name == 'Pending'" class="badge btn-warning">{{item.bookingstatus_name}}</span>
                        <span v-if="item.bookingstatus_name == 'Revoked'" class="badge btn-danger">{{item.bookingstatus_name}}</span>
                         <span v-if="item.bookingstatus_name == 'Pay Later'" class="badge btn-warning">{{item.bookingstatus_name}}</span>
                      
                      </td>
                      <td>
                       <div class="btn-group" role="group" aria-label="Basic example">
                        <button v-if="item.bookingstatus_name == 'Accepted'" @click="CheckInBooking(item)" type="button" class="btn btn-success">CheckIn</button>
                          <button v-if="item.bookingstatus_name != 'Cancelled' && item.bookingstatus_name != 'Revoked' && item.bookingstatus_name != 'CheckIn'" 
                          @click="SwitchBooking(item)" type="button" class="btn btn-warning">Change</button>
                          <button v-if="item.bookingstatus_name != 'Cancelled' && item.bookingstatus_name != 'Revoked' && item.bookingstatus_name != 'CheckIn'" @click="CancelBooking(item)" type="button" class="btn btn-danger">Cancel</button>
                        </div>
                      </td>
                    </tr>
              </tbody>
            </table>
          
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- modal-dialog modal-dialog-centered -->
    </div>
  </div>
    <!-- /.modal -->     

</template>

<script>


import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('commuter');

export default {
	 name: "ViewBookASeats",
	 props:['AddTaxiOperatorDropDownControl'],
  data: function() {
    return {
      GETALLTRANS: [],
      DataLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE"]),
  },
  components: {
  },
  methods: {
    CancelBooking: function (Item)
    {

      this.$Notify
        .YesNo({status: false,Item},{status: true,Item})
        .then((value) => {
          if (value.status) {
             var context = $(window).data("vbascontext");
             context.$store.dispatch("commuter/CancelBookingAction",{Item}).then((results)=>{
               if(results.Status){
                value.Item.bookingstatus_name = 'Cancelled';

                analytics.track("Cancel Booking", {
                  Item: value.Item
                });

                this.$Notify.Success("Success","");

               } else {
                 debugger
                this.$Notify.Error(results.Message,"");
               }
              }).catch((error)=>{
                debugger;
              this.$Notify.Error(error.Message,"");
            }).finally(()=>{
            })
          }
        });
        
      
    },
    CheckInBooking: function (Item)
    {

      this.$Notify
        .YesNo({status: false,Item},{status: true,Item})
        .then((value) => {
          if (value.status) {
             var context = $(window).data("vbascontext");
             context.$store.dispatch("commuter/CheckInBookingAction",{Item}).then((results)=>{
               if(results.Status){
                value.Item.bookingstatus_name = 'CheckIn';

                analytics.track("CheckIn Booking", {
                  Item: value.Item
                });

                this.$Notify.Success("Success","");

               } else {
                 debugger
                this.$Notify.Error(results.Message,"");
               }
              }).catch((error)=>{
                debugger;
              this.$Notify.Error(error.Message,"");
            }).finally(()=>{
            })
          }
        });
    },
    SwitchBooking: function (Item)
    {

      this.$Notify
        .YesNo({status: false,Item},{status: true,Item})
        .then((value) => {
          if (value.status) {
             var context = $(window).data("vbascontext");
             context.$store.dispatch("commuter/SwitchBookingAction",{
               Item: value.Item
               })

               analytics.track("Switch Booking", {
                Item: value.Item
               });

             $('#ViewBookASeatsDialog').modal('hide');
          }
        });
    },
    GetAllBookings: function ()
    {
      debugger
      var options;
      this.$store.dispatch("commuter/GetAllBookingsAction").then((results)=>{
         if(results.Status){
         options = {
                    buttons: [
                    ],
                    responsive: true,
                    autoFill: false,
                    colReorder: false,
                    keys: true,
                    rowReorder: false,
                    select: false,
                    paging:   true,
                    ordering: true,
                    info:     true
                  };
         
          this.GETALLTRANS = results.Data;
         } else {
           this.$Notify.Error(results.Message,"");
         }
     }).catch((error)=>{
        this.$Notify.Error("TopUp Failed",error);
     }).finally(()=>{
          if(!this.DataLoaded){
            $(".data-table-combine-viewbookings").DataTable(options)
            this.DataLoaded = true;
          }
     })
    },
  },
   mounted: function() {

     //alert("mounted");
     
    $(window).data("vbascontext",this);

     $('#ViewBookASeatsDialog').on('show.bs.modal', function() {
        // do something when the modal is shown
          var context = $(window).data("vbascontext");
          
          debugger;
          context.GetAllBookings();

           analytics.page("View Book A Seats Dialog","ViewBookASeatsDialog");
      });

  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

