<template>

  <header class="header is-sticky-bg-o-0" data-spy="scroll" data-scroll="sticky" data-sticky-hide="true" data-switch-class="shadow-none|shadow">
      <nav class="navbar navbar-light navbar-default">
        <div class="container-xl justify-content-start">
          <a class="navbar-brand d-flex position-relative mb-1" href="/">
            <img src="/assets/img/logo-light.png" class="navbar-brand-img-dark" alt="BookASeat" />
            <img src="/assets/img/logo-dark.png" class="navbar-brand-img-light" alt="BookASeat" />
            <img src="/assets/img/RentASeatLogo.png" class="navbar-brand-img-light" style="padding-left: 180px;" alt="RentATaxi" />
            <img src="/assets/img/RentASeatLogo.png" class="navbar-brand-img-light" style="padding-left: 180px;" alt="RentATaxi" />
          </a>

          <div class="ms-auto ps-3 navbar-end d-flex flex-row justify-content-end">
            <!-- <button class="btn btn-link p-0 ms-3 shadow-none" data-bs-toggle="modal" data-bs-target="#userSignInModals">
              <i class="fas fa-user"></i>
            </button> -->

            <button v-if="!ISLOGGEDON" class="btn btn-link p-0 ms-3 shadow-none" data-bs-toggle="modal" data-bs-target="#userSignInModal">
              <i class="fas fa-user"></i>
            </button>


            <button v-if="ISLOGGEDON" class="btn btn-link p-0 ms-3 shadow-none dropdown-toggle dropdown-hover" data-bs-toggle="dropdown">
              <span id="username" class="fw-bold p-1"> {{ FirstName }} {{ LastName }}</span>
              <img :src="PROPIC" v-if="HASPHOTO" id="profilepic" class="img rounded-circle icon" alt="Image" />
             
            </button>

            <ul class="nav-dropdown-menu dropdown-menu dropdown-menu-end shadow" data-bs-popper>
              <li><a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#EditUserModal">Edit Profile</a></li>
              <li><a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#NextOfKinDetails">Edit Next Of Kin</a></li>
              <li><a class="dropdown-item" @click="LogOff" href="javascript:void(0);">Log Off</a></li>
            </ul>
           

            <button class="btn-close btn-close-menu collapsed ms-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
              <span class="btn-close-icon"></span>
            </button>
          </div>

          

          <div class="collapse navbar-collapse" id="navbarMenu">
            <div class="mt-4 d-lg-none"></div>

            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

              <li class="nav-item nav-dropdown dropdown dropdown-hover">
                <a class="nav-link" href="javascript:void(0);" id="navbarHome" role="button" data-bs-toggle="dropdown">Home</a>
                
              </li>

              <li class="nav-item nav-dropdown dropdown dropdown-hover" v-if="ISLOGGEDON">
                <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarListing" role="button" data-bs-toggle="dropdown">Wallet({{BAL | currencywithSymbol}})</a>
                <ul class="nav-dropdown-menu dropdown-menu shadow">

                  <li><a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#TopUpDialog">Top Up</a></li>

                  <li><a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#TransactionsDialog">Transactions</a></li>
                </ul>
              </li>

              <li class="nav-item nav-dropdown dropdown dropdown-hover" v-if="ISLOGGEDON">
                <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarListing" role="button" data-bs-toggle="dropdown">Bookings</a>
                <ul class="nav-dropdown-menu dropdown-menu shadow">
                  <!-- <li><span class="dropdown-header text-uppercase fw-bold">History</span></li> -->

                  <li><a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#ViewBookASeatsDialog">View Bookings</a></li>

                  <li><a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#ViewRentASeatsDialog">View Rentals</a></li>
                </ul>
              </li>

            </ul>
            <!-- /.navbar-nav -->
          </div>
          <!-- /.collapse navbar-collapse -->
        </div>
        <!-- /.container-fluid -->
      </nav>

      <div class="bg header-bg position-absolute trans-base"></div>
    </header>



</template>

<script>



import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('auth');

export default {
	 name: "headercomp",
	 props:[''],
  data: function() {
    return {
      LOGO: undefined,
      HASPHOTO: false,
      PROPIC: undefined,
    };
  },
  computed: {
    ...mapGetters(["USERPROFILE","ISLOGGEDON","WALLETPROFILE"]),
    FirstName: function () {
      // `this` points to the vm instance
      try {
        return this.USERPROFILE.FirstName;
      } catch (e) {
        return '';
      }
    },
    LastName: function () {
      // `this` points to the vm instance
      try {
        return this.USERPROFILE.LastName;
      } catch (e) {
        return '';
      }
    },
    BAL: function () {
      // `this` points to the vm instance
      try {
        return this.WALLETPROFILE.account.balance;
      } catch (e) {
        return 0;
      }
    },
  },
  components: {
  },
  methods: {
     ...mapActions([
      "SignOffAction",
      "SignIn3rdPartyAction",
      "GetProfile",
    ]),
     LogOff: function() {
       
       this.SignOffAction().then(()=>{
         firebase.auth().signOut().then(function() {
        }, function(error) {
          console.error('Sign Out Error', error);
        });
         
        this.$router.go();
        }).catch(()=>{
          console.log("error");
        });

      
    },
    init: function ()
    {
      this.$emit('init')
    }
  },
   mounted: function() {

    debugger

     $(window).data("context",this);

      if(this.ISLOGGEDON)
      {
        this.GetProfile().then((results)=>{

           if(!results.Status){
             this.LogOff();
            return;
          }

            if(!results.Data.is_active || results.Data.ricablocked){
            this.$Notify.BlockScreen('Your account is blocked. Please contact your administrator On 087 236 3065.','',true);
          }

          Cohere.identify(
            results.Data.commuterid, // Required: can be any unique ID
            {
              displayName: results.Data.firstname + ' ' + results.Data.surname, // Optional
              usertype: results.Data.usergroup_name, // Optional
              IDNo: results.Data.idnumber, // Optional
              MobileNo: results.Data.mobilenumber, // Optional
            }
          );

        });

        

      }

    


     firebase.auth().onAuthStateChanged(function(user) {
      //alert(user)

      if (user) {
        // User is signed in.
        var context = $(window).data("context");

       context.$store
        .dispatch("auth/SignIn3rdPartyAction",{
         uuid:user.uid,
         authjson:user
         }).then(response => {
      if(!response.Status)
      {

        debugger

        context.$store
        .dispatch("auth/SAVEFULLNAME",{
         fullname:user.displayName,
         uid: user.uid,
         });

         debugger;

        context.$Notify.Toast("Welcome, " + user.displayName,true, false);
        $('#RegisterUserOPTModal').modal('show');

        //context.$Notify.Error(response.Message, "")
      }
      else
      {
       
        context.$Notify.Toast("Welcome Back, " + context.USERPROFILE.FirstName,true, false);
        
      }
      }).catch(error => {
      }).finally(() => {
        
      });


      debugger
        console.log(user)
        var displayName = user.displayName;
        var email = user.email;
        var emailVerified = user.emailVerified;
        var photoURL = user.photoURL;
        var uid = user.uid;
        var phoneNumber = user.phoneNumber;
        var providerData = user.providerData;
        

        user.getIdToken().then(function(accessToken) {
          //alert(accessToken);
          //$('#username').text(displayName)
          var context = $(window).data("context");

          if(user.photoURL){
            context.HASPHOTO = true;
            context.PROPIC = photoURL;
          }
          
        });
        
      } else {
         //alert(user);
        // User is signed out.
        // document.getElementById('sign-in-status').textContent = 'Signed out';
        // document.getElementById('sign-in').textContent = 'Sign in';
        // document.getElementById('account-details').textContent = 'null';
      }
    }, function(error) {
       alert(error);
    });

  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

