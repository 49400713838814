<template>
 
   <div class="modal fade" id="BookASeatDialog" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <button class="btn-close btn-close-menu position-absolute top-0 start-100 translate-middle" type="button" data-bs-dismiss="modal" aria-label="Close">
            <span class="btn-close-icon"></span>
          </button>

          <div class="modal-body">
         <div class="text-center text-uppercase fs-6 fw-bolder timeheader">Booking expires in <span class="w-bolder text-danger time" >05:00</span> minutes!</div>
                          
            <div class="container">
              <div class="card">
                  <div class="form">
                      <div class="left-side">
                          <div class="left-heading">
                              <h3>Book A Seat</h3>
                          </div>
                          <div class="steps-content">
                              <h3>Step <span class="step-number">1</span></h3>
                              <p class="step-number-content active">Details Of Bookings.</p>
                              <p class="step-number-content d-none">Choose Method Of Payment.</p>
                              <p class="step-number-content d-none">Confirmation Of Booking.</p>
                          </div>
                          <ul class="progress-bar">
                              <li class="active">Summary</li>
                              <li>Payment</li>
                              <li>Confirmation</li>
                          </ul>
              
                      </div>
                      <div class="right-side">
                          <div class="main active">
                              <div class="bg-pay p-2"> 
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Booking Date</span> <span>{{GETBOOKINGDETAILS.BookingDateText}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Rank</span> <span>{{GETBOOKINGDETAILS.Rank}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Destination</span> <span>{{GETBOOKINGDETAILS.Destination}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Departure Time</span> <span>{{GETBOOKINGDETAILS.DepartureTime}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Frequency</span> <span>{{GETBOOKINGDETAILS.Frequency}}</span> </div>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Passangers</span> <span>{{GETBOOKINGDETAILS.Seats}}</span> </div>
                              <hr>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Sub Total</span><span>(Fare Price: {{GETBOOKINGDETAILS.FarePrice | newcurrencywithSymbol}})</span> <span>{{GETBOOKINGDETAILS.SubTotal | newcurrencywithSymbol}}</span> </div>
                              <!-- <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Booking Fee</span><span style=" text-decoration: line-through;">{{BOOKINGFEESTRIKE | newcurrencywithSymbol}}</span> <span>{{BOOKINGFEE | newcurrencywithSymbol}}</span>  </div> -->
                               <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Booking Fee</span><span style=" text-decoration: line-through;"></span> <span>{{BOOKINGFEE | newcurrencywithSymbol}}</span>  </div>
                              <!-- <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Booking Fee</span><span>{{BOOKINGFEE | newcurrencywithSymbol}}</span>  </div> -->
                              <hr>
                              <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Total </span> <span class="text-success">{{GETBOOKINGDETAILS.Total + BOOKINGFEE | newcurrencywithSymbol}}</span> </div>
                            </div>
                            <div class="buttons">
                              <button class="next_button">Next</button>
                          </div>
                          </div>
                          <div class="main">
                                         

<ul class="nav nav-pills nav-fill bg-light rounded">
   <li class="nav-item"> <a data-toggle="pill" href="#Wallet" class="nav-link active"> <i class="fas fa-wallet mr-2"></i> Wallet </a> </li>
  <li class="nav-item"> <a data-toggle="pill" href="#card" class="nav-link "> <i class="fas fa-credit-card mr-2"></i> Card & EFT </a> </li>
    <li class="nav-item"> <a data-toggle="pill" href="#PayLater" class="nav-link "> <i class="fas fa-receipt mr-2"></i> Book & Pay Later </a> </li>

</ul>

<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="Wallet" role="tabpanel" aria-labelledby="home-tab">
      <div class="form-group py-6">
                          <p class="bottom-100"> <button 
                          @click="SubmitBooking(JOINWAITINGLIST ? 'OWL' : 'ACT','TPW')"
                          :disabled="ISSubmitBooking" 
                          
                          type="button" class="btn btn-success btn-lg">
                          <i v-show="ISSubmitBooking" class="fas fa-spinner fa-spin"></i>
                                  Pay Now <span>{{GETBOOKINGDETAILS.Total + this.BOOKINGFEE | newcurrencywithSymbol}} </span>
                           

                           </button> </p>
                      </div>
                      <p class="text-muted">Note: After clicking on the button, you will be directed to a secure gateway for payment. After completing the payment process, you will be redirected back to the website to view details of your order. </p>
                  
  </div>
  <div class="tab-pane fade" id="card" role="tabpanel" aria-labelledby="profile-tab">
     
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item" v-if="CARDS.length > 0">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        Pay By Card
      </button>
    </h2>
    <div id="flush-collapseOne" :class="CARDS.length > 0 ? 'show' : 'dontshow'" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div v-if="CARDS.length > 0" class="form-group mb-1"> <label for="Select Your Bank">
                                                <h6>Select Your Card</h6>
                                            </label> 
                                <select v-if="CARDS.length > 0" class="select" id="ccmonth"  v-model="SELECTEDCARDS">
                                                <option :key="option.bankcardsid" v-for="option in CARDS" v-bind:value="option">
                                                  {{ option.paymentbrand }} {{ option.cardno }} Exp: {{ option.expirymonth }}/{{ option.expiryyear }}
                                                </option>
                                            </select> </div>

                                    <div class="mb-0 position-relative d-flex flex-column">
                                       <h6 class="text-secondary my-1">Amount</h6>
                                  <div class="mb-1 position-relative">
                                    <CurrencyInput
                                v-model="Amount"
                                :currency="currency"
                                :auto-decimal-mode="true"
                                class="form-control form-control shadow-none py-2"
                              />
                                  </div>
                                  <!-- /.btn-group btn-select-dropdown -->
                                </div>

                                <div class="form-group p-3">
                                                  <p> <button :disabled="ISSubmitBooking" @click="TopUp" type="button" class="btn btn-primary btn-lg me-4">
                                                  
                                                    <i v-show="ISSubmitBooking" class="fas fa-spinner fa-spin"></i>
                                                    Pay Now
                                                  </button> 
                                                  </p>
                                                    <div class="invoice-header">
                              <div v-show="TOPUPSUCCESS" class="alert alert-success alert-dismissible fade show">
                                  <span class="close" data-dismiss="alert">×</span>
                                  <strong class="alert-heading"> TopUp Of {{Amount | newcurrencywithSymbol}} Was Successfully.</strong>
                                </div>
                              </div>
                                              </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
       Pay By New Card
      </button>
    </h2>
    <div id="flush-collapseTwo" :class="CARDS.length == 0 ? 'show' : 'dontshow'" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <div class="mb-0 position-relative d-flex flex-column">
                                  <h6 class="text-secondary my-1">Amount</h6>
                                  <div class="mb-0 position-relative">
                                    <CurrencyInput
                                v-model="Amount"
                                :currency="currency"
                                :auto-decimal-mode="true"
                                class="form-control form-control shadow-none py-2"
                              />
                                  </div>
                                  <!-- /.btn-group btn-select-dropdown -->
                                </div>

                                <div class="form-group p-3">
                                                  <p> 
                                                    <button :disabled="this.$wait.is('LoggingIn') || Amount < 5" v-if="CARDS.length >= 0" type="submit" @click="payWithPaystack" class="subscribe btn btn-success btn-lg ">
                                                    <v-wait for="LoggingIn">
                                                      <template slot="waiting">
                                                        <i class="fas fa-spinner fa-spin"></i>
                                                      </template>
                                                      <i class="fas fa-credit-card mr-2"></i>
                                                        Pay With New Card/EFT
                                                    </v-wait>
                                                    </button>
                                                </p>
                                </div>
                                <div v-show="TOPUPSUCCESS" class="alert alert-success alert-dismissible fade show">
                                  <span class="close" data-dismiss="alert">×</span>
                                  <strong class="alert-heading"> TopUp Of {{Amount | newcurrencywithSymbol}} Was Successfully.!</strong>
                                </div>

      </div>
    </div>
  </div>
</div>

  </div>
  <div class="tab-pane fade" id="PayLater" role="tabpanel" aria-labelledby="contact-tab">
      <div class="form-group py-6">
                          <p class="bottom-50"
                          v-if="CANPAYLATER"
                          > <button 
                          v-if="!JOINWAITINGLIST"
                          @click="SubmitBooking(JOINWAITINGLIST ? 'OWL' : 'PYL','POA')"
                          :disabled="ISSubmitBooking" 
                          
                          type="button" class="btn btn-success btn-lg">
                            <i v-show="ISSubmitBooking" class="fas fa-spinner fa-spin"></i>
                              Pay Later <span>{{GETBOOKINGDETAILS.Total + this.BOOKINGFEE | newcurrencywithSymbol}}</span>
                           </button> </p>
                      </div>
                      <p class="text-muted">Note: After clicking on the button, You can Pay At The Taxi Rank. </p>
    
  </div>
</div>


                              <div class="buttons button_space">
                                  <button class="back_button back_button22">Back</button>
                                  <button class="next_button hide next_button22">Next Step</button>
                              </div>
                          </div>
                          <div class="main">
                            <div class="bg-pay p-3"> 
                              <table id="data-table-combine-submitbooking" class="table table-striped table-bordered data-table-combine data-table-combine-submitbooking">
              <thead>
                <tr>
                  <th class="text-nowrap">Date</th>
                  <th class="text-nowrap">Booking No</th>
                  <th class="text-nowrap">Price</th>
                  <th class="text-nowrap">Status</th>
                  <!-- <th class="text-nowrap">Num Of Passangers</th>
                  <th class="text-nowrap">Total Fare Generated</th> -->
                </tr>
              </thead>

               <tbody>
                <tr v-for="(item) in BOOKINGS" :key="item.TransactionsId">
                      <td>{{item.DepartureDate}} </td>
                      <td>{{item.BookingNo}} </td>
                      <td>{{GETBOOKINGDETAILS.Total + BOOKINGFEE | newcurrencywithSymbol}}</td>
                      <td>
                        <span v-if="item.Status == 'Accepted'" class="btn-success badge">{{item.Status}}</span>
                        <span v-if="item.Status == 'CheckIn'" class="badge btn-success">{{item.Status}}</span>
                        <span v-if="item.Status == 'Cancelled'" class="badge btn-danger">{{item.Status}}</span>
                        <span v-if="item.Status == 'Waiting List'" class="badge btn-warning">{{item.Status}}</span>
                        <span v-if="item.Status == 'Pending'" class="badge btn-warning">{{item.Status}}</span>
                        <span v-if="item.Status == 'Revoked'" class="badge btn-danger">{{item.Status}}</span>
                        <span v-if="item.Status == 'Pay Later'" class="badge btn-warning">{{item.Status}}</span>
                      </td>
                    </tr>
              </tbody>
            </table>
                           </div>
                            <p class="text-muted">Note: Give Booking Ref Or QR Code To The Rank Marshal </p>
                           <div class="alert alert-success alert-dismissible fade show">
                                  <span class="close" data-dismiss="alert">×</span>
                                  <strong class="alert-heading"> Congratulations, Booking Was Successful!</strong>
                                </div>
                          <div class="buttons hide">
                            <button class="next_button">Done</button>
                          </div>
                        </div>
                        
                      </div>
                  </div>
              </div>
          </div>

          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- modal-dialog modal-dialog-centered -->
    </div>
    <!-- /.modal -->

</template>

<script>


import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('commuter');

export default {
	 name: "BookASeatDialog",
	 props:[],
  data: function() {
    return {
      SELECTEDCARDS: undefined,
      TOPUPSUCCESS: false,
      BOOKINGS:[],
      SUBMITTED: false,
      ISSubmitBooking:false,
    };
  },
  computed: {
    ...mapGetters({CARDS:"GETCARDS",GETBOOKINGDETAILS:"GETBOOKINGDETAILS",USERPROFILE:"USERPROFILE",
    GETSWITCHBOOKINGID:"GETSWITCHBOOKINGID",GETSWITCHBOOKING:"GETSWITCHBOOKING",JOINWAITINGLIST:"JOINWAITINGLIST",
    WALLETPROFILE:"WALLETPROFILE"}),
    BAL: function () {
      // `this` points to the vm instance
      try {
        
        return this.WALLETPROFILE.account.balance;
      } catch (e) {
        return 0;
      }
    },
    CANPAYLATER: function () {
      // `this` points to the vm instance
      try {
        
        return this.WALLETPROFILE.canpaylater;
      } catch (e) {
        return false;
      }
    },
    Amount: function () {
      // `this` points to the vm instance
      try {

        return this.GETBOOKINGDETAILS.Total + this.BOOKINGFEE;
      } catch (e) {
        return 0;
      }
    },
    BOOKINGFEE: function () {
      // `this` points to the vm instance BookingFee
      try {

        return ((this.GETBOOKINGDETAILS.Total * this.GETBOOKINGDETAILS.BookingFee)/100);
      } catch (e) {
        return 0;
      }
    },
    BOOKINGFEESTRIKE: function () {
      // `this` points to the vm instance
      try {

        return (((this.GETBOOKINGDETAILS.Total * this.GETBOOKINGDETAILS.BookingFee)/100)*2);
      } catch (e) {
        return 0;
      }
    },
    currency() {
      return { [this.position]: this.currencySymbol };
    },
  },
  components: {
  },
  methods: {
    SubmitBooking: function (BookingStatusCode,
    TransactionSourceCode)
    {
      //alert(this.SELECTEDCARDS.token);

      //$('.next_button2').click();

     this.ISSubmitBooking = true;

    //    this.TOPUPSUCCESS = false;

      analytics.track("Submit Book A Seat Booking", {
         RankRoutesId: this.GETBOOKINGDETAILS.DestinationId.RankRouteId,
        DepartureTimeId: this.GETBOOKINGDETAILS.DepartureId.departuretime_id,
        NoOfSeats: this.GETBOOKINGDETAILS.Seats,
        BookingDate: this.GETBOOKINGDETAILS.BookingDate,
        FareAmount: this.GETBOOKINGDETAILS.Total + this.BOOKINGFEE,
        BookingStatusCode: BookingStatusCode,
        SendNotification: true,
        FrequencyCode:this.GETBOOKINGDETAILS.FrequencyId.code,
        TransactionSourceCode: TransactionSourceCode,
       });

       debugger;

       var actionurl = !this.GETSWITCHBOOKING ? "commuter/SubmitBookASeatAction" : "commuter/SwitchBookASeatAction";

       this.$store
        .dispatch(actionurl,{
        BookSeatsId: this.GETSWITCHBOOKING ? this.GETSWITCHBOOKINGID.bookaseatid : undefined,
         RankRoutesId: this.GETBOOKINGDETAILS.DestinationId.RankRouteId,
        DepartureTimeId: this.GETBOOKINGDETAILS.DepartureId.departuretime_id,
        BookSeatsSessionId: this.GETBOOKINGDETAILS.DepartureId.bookingseats_sessionid,
        NoOfSeats: this.GETBOOKINGDETAILS.Seats,
        BookingDate: this.GETBOOKINGDETAILS.BookingDate,
        FareAmount: this.GETBOOKINGDETAILS.Total + this.BOOKINGFEE,
        BookingStatusCode: BookingStatusCode,
        SendNotification: true,
        FrequencyCode:this.GETBOOKINGDETAILS.FrequencyId.code,
        TransactionSourceCode: TransactionSourceCode,
       }).then((results)=>{
        debugger;
        this.ISSubmitBooking = false;
         if(results.Status){
          
          debugger;

         
          this.SUBMITTED = true;

           this.BOOKINGS = results.Data;

           this.$store.dispatch('commuter/CancelSwitchBookingAction');
           EndCount();

        this.$store
        .dispatch("auth/GetProfile").then(()=>{
  
         $('.next_button22').click();

        });
          // do something...
         

         } else {
           this.$Notify.Error("TopUp Failed",results.Message);
         }
         
     }).catch(()=>{
     }).finally(()=>{
       

                  //$(".data-table-combine-submitbooking").DataTable(options)
     });

    },
    TopUp: function ()
    {
      //alert(this.SELECTEDCARDS.token);

      debugger;

      var context = $(window).data("BookASeatDialog");

      context.$wait.start("LoggingIn");

       context.TOPUPSUCCESS = false;

       this.$store
        .dispatch("commuter/TopUpWalletAction",{
         BankCardId:this.SELECTEDCARDS.bankcardsid,
         CardNo:this.SELECTEDCARDS.token,
         Amount:this.Amount,
       }).then((results)=>{
         if(results.Status){

          debugger;
          
          analytics.track("Top Up Wallet", {
            BankCardId:context.SELECTEDCARDS.bankcardsid,
            CardNo:context.SELECTEDCARDS.token,
            Amount:context.Amount,
          });

          context.TOPUPSUCCESS = true;
          context.SubmitBooking(this.JOINWAITINGLIST ? 'OWL' : 'ACT','TPW');
         } else {
           context.$Notify.Error(results.Message);
         }
         
     }).catch(()=>{
     }).finally(()=>{
       context.$wait.end("LoggingIn");
     });

    },
    payWithPaystack: function (){
        //e.preventDefault();

  $(window).data("BookASeatDialog",this);

  var urlParams = new URLSearchParams(window.location.search);
	//  userid = urlParams.get('id')
	//  email = urlParams.get('email')
	//  mobile = urlParams.get('mobile')

  var userid = this.USERPROFILE.SystemUsersId;
	 var email =  this.WALLETPROFILE.email;
	 var mobile = this.USERPROFILE.MobileNumber;
   var topupamount = this.Amount;

  let handler = PaystackPop.setup({
    key: 'pk_live_a1869bc457449b0aa329202e83905961d960a6af', // Replace with your public key
    email: email,
    amount: topupamount * 100,
	  currency: "ZAR",
    ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
    // label: "Optional string that replaces customer email"
    onClose: function(){
      alert('Window closed.');
    },
    callback: function(response){
      let message = 'Payment complete! Reference: ' + response.reference;
      //alert(message);

	  //$('.subscribe').text('Refunding R5...')

    debugger;


     var context = $(window).data("BookASeatDialog");

     context.$wait.start("LoggingIn");

      context.TOPUPSUCCESS = false;

      analytics.track("TopUp Via New Card", {
        transaction: response.transaction,
        reference : response.reference,
        response: response,
       });

      context.$store
        .dispatch("commuter/AddBankCardAndCharge",{
        transaction: response.transaction,
        reference : response.reference,
        response: response,
       }).then((results)=>{
         
         if(results.Status){
          context.TOPUPSUCCESS = true;
          context.SubmitBooking(context.JOINWAITINGLIST ? 'OWL' : 'ACT','TPW');
         } else {
          err.$Notify.Error(results.Message);
         }
     }).catch((err)=>{
        
        alert(JSON.stringify(err));
     }).finally(()=>{
       context.$wait.end("LoggingIn");
     });

    }
    });
    handler.openIframe();
    },
  },
   mounted: function() {

      $(window).data("BookASeatDialog",this);


      $('#BookASeatDialog').on('show.bs.modal', function() {
        // do something when the modal is shown
        var context = $(window).data("BookASeatDialog");

         
       
        if(context.SUBMITTED){
          context.SUBMITTED = false;
          $('.back_button22').click();
          $('.back_button22').click();
        } else {
          //context.Amount = context.GETBOOKINGDETAILS.Total;
        }

        //this.GETBOOKINGDETAILS.DepartureId
        StartCount(10);

        analytics.page("Book A Seat Dialog","BookASeatDialog");
          
      });

      $('#BookASeatDialog').on('hidden.bs.modal', function (e) {

        
            var context = $(window).data("BookASeatDialog");
            if(context.WALLETPROFILE.nexofkeen_mobilenumber === null || 
            context.WALLETPROFILE.nexofkeen_mobilenumber === undefined
            ){
              $('#NextOfKinDetails').modal('show');
          } else {
              if(context.BOOKINGS.length > 0)
                $('#SharedWithSocials').modal('show')
          }
     })


 var main_form=document.querySelectorAll("#BookASeatDialog .main");
//var main_form=$('.main','#BookASeatDialog')
var step_list = document.querySelectorAll(".progress-bar li");
var num = document.querySelector(".step-number");
let formnumber=0;

$('.next_button','#BookASeatDialog').on('click',()=>{
       formnumber++;
       //alert("next_click_form");
       updateform();
       progress_forward();
       contentchange();
});

$('.back_button','#BookASeatDialog').on('click',()=>{
       formnumber--;
       updateform();
       progress_backward();
       contentchange();
});


 

function updateform(){
    main_form.forEach(function(mainform_number){
        mainform_number.classList.remove('active');
    })
    main_form[formnumber].classList.add('active');
} 
 
function progress_forward(){     
   
    num.innerHTML = formnumber+1;
    step_list[formnumber].classList.add('active');
}  

function progress_backward(){
    var form_num = formnumber+1;
    step_list[form_num].classList.remove('active');
    num.innerHTML = form_num;
} 
 
var step_num_content=document.querySelectorAll(".step-number-content");

 function contentchange(){
     step_num_content.forEach(function(content){
        content.classList.remove('active'); 
        content.classList.add('d-none');
     }); 
     step_num_content[formnumber].classList.add('active');
 } 
 
     
  },
}
   
</script>

<style scoped>
.taxissociationbranding
{
  width: 100px;
	position: absolute;
	left: 0;
	right: 0;margin: auto;
}
</style>

