/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */

// options...
export default {
  // options...
  

  // BASE_URL: () => process.env.WEBSERVICEBASEURL || 'https://basmainapi.ngrok.io/api/v1',
  // BASEFIN_URL: () => process.env.WEBSERVICEBASEFINURL || 'https://basfinapi.ngrok.io/api/v1',
  //BASE_URL: () => process.env.WEBSERVICEBASEURL || 'https://mainapi.bookaseat.africa/api/v1',
  // BASESOCKET_URL: () => process.env.WEBSERVICEBASESOCKETURL || 'https://tams-api.herokuapp.com/',
  
  BASE_URL: () => process.env.WEBSERVICEBASEURL || 'https://mainapi.bookaseat.africa/api/v1',
  BASEFIN_URL: () => process.env.WEBSERVICEBASEFINURL || 'https://finapi.bookaseat.africa/api/v1',

  
  //BASE_URL: () => process.env.WEBSERVICEBASEURL || 'https://d6e8-102-222-250-236.ngrok.io/api/v1',
  //BASEFIN_URL: () => process.env.WEBSERVICEBASEFINURL || 'https://0003-41-169-160-57.ngrok.io/api/v1',
  // BASESOCKET_URL: () => process.env.WEBSERVICEBASESOCKETURL || 'https://tamsapi-bc6zqxje4a-uc.a.run.app/',

  // BASE_URL: () => process.env.WEBSERVICEBASEURL || 'http://localhost:3001/api/v1',
  // BASESOCKET_URL: () => process.env.WEBSERVICEBASESOCKETURL || 'http://localhost:3001/',
  // BASEFIN_URL: () => process.env.WEBSERVICEBASEFINURL || 'http://localhost:3002/api/v1',

  // BASE_URL: () => process.env.WEBSERVICEBASEURL || 'http://7ef072c7a787.ngrok.io/api/v1',
  // BASEFIN_URL: () => process.env.WEBSERVICEBASEFINURL || 'http://77286cc08a48.ngrok.io',
   
}